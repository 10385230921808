import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 497.000000 497.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,497.000000) scale(0.100000,-0.100000)">

<path d="M2 3827 c1 -628 3 -1124 5 -1102 8 106 27 216 39 226 8 6 11 16 8 21
-6 10 23 143 37 168 7 13 4 -32 -6 -83 -2 -9 1 -15 6 -11 5 3 9 15 9 27 0 16
3 18 12 9 10 -10 10 -17 0 -33 -8 -12 -9 -18 -3 -14 6 3 11 2 11 -3 0 -5 9
-12 19 -15 15 -4 20 2 25 26 7 32 -20 173 -35 193 -5 6 -7 19 -4 28 16 51 17
52 26 21 13 -43 39 -227 33 -236 -2 -4 0 -10 6 -14 5 -3 10 -18 10 -32 0 -18
-4 -24 -15 -19 -9 3 -15 0 -15 -10 0 -10 6 -14 14 -11 17 7 22 -12 36 -138 9
-88 6 -656 -4 -725 -3 -19 -8 -126 -11 -236 -3 -131 -10 -215 -18 -240 -8 -22
-12 -45 -10 -51 3 -7 0 -13 -5 -13 -12 0 -85 207 -112 318 -11 45 -29 130 -39
190 l-19 107 -1 -1087 -1 -1088 2485 0 2485 0 0 2485 0 2485 -2485 0 -2485 0
2 -1143z m4294 357 c8 3 16 1 19 -4 8 -13 -37 -5 -62 11 -13 7 -23 20 -23 28
0 9 10 4 27 -13 14 -15 32 -25 39 -22z m-1393 -13 c45 -15 46 -29 7 -93 -16
-26 -30 -53 -30 -58 0 -6 -4 -10 -10 -10 -5 0 -10 -4 -10 -9 0 -5 -35 -69 -79
-143 -43 -73 -106 -181 -141 -240 l-62 -108 -39 44 c-77 87 -176 107 -431 86
-144 -12 -164 -16 -189 -37 -30 -26 -47 -92 -34 -133 14 -46 79 -94 245 -182
85 -46 145 -83 227 -140 81 -57 82 -58 151 -58 l70 0 27 -72 c96 -255 218
-514 470 -993 180 -342 250 -486 287 -582 11 -29 23 -53 27 -53 17 0 57 326
116 950 75 789 138 1217 212 1454 39 125 98 239 136 261 77 45 155 0 255 -146
43 -64 163 -314 211 -442 l41 -107 -24 -48 c-21 -41 -109 -169 -125 -182 -21
-16 -107 -143 -127 -185 -77 -168 -104 -326 -205 -1230 -45 -405 -54 -464 -72
-491 -12 -17 -82 -94 -156 -172 -123 -130 -138 -143 -195 -162 -125 -44 -109
-49 -146 49 -29 78 -210 449 -294 604 -35 65 -91 170 -196 372 -35 66 -83 156
-108 200 -24 44 -60 108 -79 143 -19 34 -37 62 -39 62 -2 0 -11 -26 -19 -57
-51 -181 -69 -246 -92 -318 -13 -44 -55 -199 -93 -345 -78 -301 -111 -398
-168 -497 -64 -109 -130 -160 -257 -200 -85 -26 -536 -26 -634 0 -96 26 -271
122 -271 148 0 5 41 53 91 107 l91 99 43 -42 c59 -59 126 -75 211 -50 81 23
157 72 232 147 68 69 90 98 128 170 24 48 134 367 134 391 0 10 -36 13 -157
14 -167 1 -365 22 -678 73 -376 61 -693 142 -682 173 2 7 111 98 242 203 223
180 240 192 259 178 29 -20 139 -18 275 6 163 29 175 28 250 -25 69 -48 147
-72 203 -61 47 9 88 54 95 103 11 84 -46 144 -247 258 -199 113 -279 197 -345
360 -26 63 -29 84 -33 222 -3 89 0 178 7 216 64 366 319 554 756 558 50 0 180
9 290 19 229 21 614 22 678 1z m1344 3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m78 -41 c7 -17 8 -16 15 2 8 17 9 16 9 -8 1 -28 1 -28 -24 -10
-25 18 -32 33 -16 33 5 0 12 -8 16 -17z m102 -80 c4 -10 14 -25 23 -33 9 -8
11 -12 5 -8 -7 4 -14 6 -16 4 -2 -3 -12 9 -21 25 -9 16 -13 29 -7 29 5 0 13
-8 16 -17z m63 -73 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m-6 -43 c7 -6 6 -7 -4 -4 -8 2 -16 12 -17 23 -2 13 -1 15 4 4 3
-8 11 -19 17 -23z m10 -74 c3 -7 -1 -13 -9 -13 -8 0 -15 6 -16 13 0 10 -2 10
-6 0 -2 -7 -9 -13 -15 -13 -7 0 -7 5 1 13 6 8 17 14 25 14 9 0 17 -6 20 -14z
m77 0 c13 -16 12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z m-16 -13
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m15 -50
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m31
-57 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m38 -37 c6 -7
8 -21 5 -31 -4 -10 -1 -21 7 -25 7 -5 10 -12 7 -15 -12 -13 -28 6 -28 34 0 15
-5 32 -12 39 -8 8 -8 12 -1 12 6 0 16 -6 22 -14z m45 -42 c3 -8 2 -12 -4 -9
-6 3 -10 10 -10 16 0 14 7 11 14 -7z m32 -68 c-1 -12 4 -20 10 -19 6 1 10 -8
10 -20 -1 -13 4 -21 12 -19 6 1 12 -5 12 -13 0 -14 -3 -13 -25 1 -14 9 -25 24
-25 33 0 9 -7 26 -15 38 -14 20 -14 22 3 20 11 0 18 -9 18 -21z m-17 -53 c0
-5 -4 -17 -9 -28 -8 -18 -9 -17 -9 8 -1 15 4 27 9 27 6 0 10 -3 9 -7z m35 -72
c-6 -6 -24 19 -24 34 1 6 7 1 15 -9 8 -11 12 -22 9 -25z m47 -14 c-10 -9 -11
-8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-4674 -279 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m14 -71 c-13 -13 -15 1 -4 30 7 17 8 17 11 -1
2 -10 -1 -23 -7 -29z m4766 -9 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19
0 -25z m-4730 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m129
-1668 c1 -33 0 -60 -2 -60 -2 0 -13 18 -25 41 -11 23 -16 38 -11 35 6 -3 13 8
17 25 10 44 19 27 21 -41z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
